import { config } from "@/lib/config";
import getMessage from "@/lib/utils/getMessage";
import getRecordArraySwr from "@/lib/utils/admin/server/record/getRecordArraySwr";
import getRecordArray from "@/lib/utils/admin/server/record/getRecordArray";
import getServerSideSecurity from "@/lib/utils/admin/server/getServerSideSecurity";
import IndexPosts from "pages";

export async function getServerSideProps(context) {
  //log the visit and return other relevant page / user data
  const { forbidden, user, ip, cookie, ssr_data_tribe } =
    await getServerSideSecurity(context);
  if (forbidden !== "") {
    context.res.write(forbidden);
    context.res.end();
  }

  const ssr_data_posts = await getRecordArraySwr(
    "post", //table_name: string
    "select_by_featured", //path_name: string
    "0", //user_id: string
    "0", //my_id:string
    ssr_data_tribe?.tribe_id, //tribe_id: string
    "1", //page: string
    "RAND", //sort: string
    config.itemdir, //dir: string
    "" //filter: string
  );

  const ssr_data_show = await getRecordArray(
    "show", //table_name: string
    "select_by_today", //path_name: string
    "0", //user_id: string
    ssr_data_tribe?.tribe_id, //tribe_id: string
    "0", //page: string
    "RAND", //sort: string
    config.itemdir, //dir: string
    "" //filter: string
  );

  const ssr_data_live = await getRecordArray(
    "user", //table_name: string
    "select_by_live", //path_name: string
    "0", //user_id: string
    ssr_data_tribe?.tribe_id, //tribe_id: string
    "0", //page: string
    "broadcast", //sort: string
    config.itemdir, //dir: string
    "" //filter: string
  );

  const navurl_relative = `/`;

  return {
    props: {
      ssr_data_tribe: ssr_data_tribe,
      ssr_data_posts: ssr_data_posts,
      ssr_data_live: ssr_data_live,
      ssr_var_live_filter:
        ssr_data_live?.data?.filter(
          (e) =>
            e?.bol_featured == 1 &&
            e?.bol_show_chat == 1 &&
            e?.chat_permissions !== 0
        )?.length == 0
          ? -1
          : 1,
      ssr_data_show: ssr_data_show,
      //  ,ssr_data_hosts: ssr_data_hosts
      params: context?.params || null,
      navtype: "posts",
      navoption: "featured",
      navtitle: "Home Page",
      navurl: `${ssr_data_tribe?.server_website}${navurl_relative}`,
      navurl_relative: navurl_relative,
      helptype: "broadcast",
      nodata: getMessage("noresults", ssr_data_tribe),
      ssr_userdata: user,
    },
    //,revalidate: 300, // In seconds
  };
}

// export async function getStaticPaths() {

//   return {
//     paths: [],
//     fallback: 'blocking',
//   }
// }

export default IndexPosts;
